.main-row {
  display: flex;
  height: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-items-list {
  padding: 4px 15px;
  min-height: 63vh;
  overflow-y: auto;
  width: 300px;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.heading > input{
  font-size: 16px;
  padding: 15px;
  text-align: center;
  background-color: #e3e3e3;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.column-items-list::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}

.column-items-list::-webkit-scrollbar-thumb {
  background-color: rgb(13, 110, 253);
  border-radius: 30px;
  width: 10px;
}

.column-items-list::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 1050px) {
  .main-row {
    overflow-x: auto;
  }
  .column-items-list {
    max-height: 60vh;
  }
}
