.core {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
 .core_h{
    font-size: x-large;
    white-space: nowrap;
    text-align: start;
}
.core_p {
    margin: auto;
    padding: 1rem;
    white-space: nowrap;
    font-style: italic;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: large;
}

.core_right {
    margin-right: 30px;
}

.core_img {
    background-color: #f2f2f2;
    height: 150px;
    width:  220px;
}
.edit-core{
    display: flex;
    flex-direction: column;
}
.edit_core_info{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.core-list {
    text-align: start;
    margin-left: 15px;
}
