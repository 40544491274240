.research-table{
  width: auto;
  margin-inline: auto;
  min-width: 70vw;
}

.research-table > thead > tr > th {
  padding-inline: 15px;
}

.research-table > tbody > tr > td {
  padding-inline: 15px;
}

.research-table > thead > tr > th {
  position: sticky;
  top: 0;
  background-color: #ffffff; /* Ensure it has a background to cover content below */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle shadow for separation */
}

.heading-name{
  text-align: start !important;
  font-size: 30px;
}

.subheading {
  text-align: start !important;
  font-size: 16px;
}

/* Styles for the overlay and spinner container */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
