.login-page {
  background-color: white;
  height: 100%;
}

.login-form-container {
  max-width: 40rem;
}

.auth-logo {
  width: 168px;
  height: 45px;
}

@media only screen and (min-width: 768px) {
  /* .login-page {
    background-image: url("../../assets/img/login.png");
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 1000px 100vh;
    background-position: top;
    background-position-x: right;
    padding-bottom: 3rem;
  } */
}

@media only screen and (min-width: 768px) {
  .login-form-container {
    width: 85%;
    margin-left: auto;
    margin-right: 5%;
  }

  .auth-logo {
    width: auto;
    height: auto;
  }

  .login-page-div {
    height: 80vh;
  }

  .login-page {
    background-image: url("../../assets/img/login.png");
    /* width: 100vw; */
    height: 100vh;

    background-repeat: no-repeat;
    /* background-size: 900px 65vh; */
    /* background-position: top; */
    /* background-position-x: right; */
    /* background: transparent linear-gradient(180deg, #000000ef 0%, #6c6c6c 100%) 0%
    0% no-repeat padding-box; */
    /* opacity: 0.8; */
    /* font-weight: bolder; */
    /* height: 60vh; */
    /* padding-bottom: 3rem; */
    /* padding-top: 40%; */
    /* width: 100%;
  height: 100%; */

    background-attachment: fixed;
    background-position: center top;

    background-size: cover;

    /* display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; */
  }
}

@media only screen and (min-width: 1024px) {
  .login-form-container {
    width: 70%;
    margin-right: 10%;
  }
}
