.project-srr {
  overflow-y: scroll !important;
  overflow-x: clip !important;
  height: 100%;
}

.srr-card {
  padding: 0.5rem;
}

.textarea-notes {
  resize: vertical;
  max-height: 5rem;
}

@media only screen and (min-width: 1224px) {
  .project-srr {
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1724px) {
  .project-srr {
    height: 68vh;
  }
}
