.research-table{
  width: auto;
  margin-inline: auto;
  min-width: 70vw;
}

.research-table > thead > tr > th {
  padding-inline: 15px;
}

.research-table > tbody > tr > td {
  padding-inline: 15px;
}

.heading-name{
  text-align: start !important;
  font-size: 30px;
}

.subheading {
  text-align: start !important;
  font-size: 16px;
}

/* Styles for the overlay and spinner container */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tbody-tr:nth-child(odd) td {
  background-color: #f3f3f3;
}
