/* .increase-search-width {
  width: 400px;
}

.search-width {
  width: 300px;
} */

.search-icon-svg {
  top: 15%;
}
.search-box-input {
  font-size: 1.3rem;
}
.nav-search-input {
  padding-left: 3.5rem;
}

.active-search-input {
  padding-left: 3rem;
}
.active-search-input::placeholder {
  padding-left: 0.5rem;
}

.map-search-input .search-icon-container {
  display: none;
}

/* .search-box-input:active + .search-icon-container {
  display: flex;
}

.search-box-input:not(:active) + .search-icon-container {
  display: none;
} */

.clear-search-icon {
  position: absolute;
    top: 20%;
    right: 2%;
}

@media only screen and (min-width: 768px) {
  .map-search-input {
    width: 300px;
    border: none;
  }
  .increase-search-width {
    width: 400px;
    box-shadow: 0px 0px 0px 2px rgba(208, 231, 255, 0.25);
    border-radius: 4px;
    border: 1px solid #ffffff;
  }

  .search-width {
    width: 300px;
  }
}
