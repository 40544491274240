.slider {
  width: 100%;
  -webkit-appearance: none; /* Remove default styles */
  appearance: none;
  height: 10px; /* Set the height of the slider */
  background: #ddd; /* Slider track color */
  outline: none; /* Remove focus outline */
  border-radius: 5px;
  overflow: visible; /* Allows the icon to overflow */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styles */
  appearance: none;
  width: 30px; /* Set the width of the thumb */
  height: 30px; /* Set the height of the thumb */
  background: #007bff; /* Thumb color */
  border-radius: 50%; /* Make it circular */
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.slider-markers {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 5px; /* Adjust as needed */
  margin-top: 10px; /* Adjust as needed */
}

.slider-markers-lines {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -15px;
}

.slider-marker {
  position: absolute;
  font-size: 12px;
  transform: translateX(-50%);
  color: #333; /* Marker text color */
  transition: 0.5s all ease-in;
}

.slider-maker-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Additional styles for the slider track */
.slider::-webkit-slider-thumb::before {
  content: "\f155"; /* Unicode for dollar sign icon */
  font-family: "Font Awesome 5 Free"; /* Make sure you have Font Awesome included */
  font-size: 20px; /* Adjust icon size */
  color: white; /* Icon color */
  position: absolute;
  top: 50%; /* Vertically center the icon */
  left: 50%; /* Horizontally center the icon */
  transform: translate(-50%, -50%);
  z-index: 2;
}

.slider-divider {
  width: 2px;
  height: 80px;
  margin-top: 15px;
  background-color: #e9e9e9; /* Line color */
  margin: 15px 10px 0 10px; /* Adjust the spacing */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background: url('../../assets/icons/arrows.svg');
  cursor: pointer;
}

.slider::-moz-range-thumb {
  border: 0;
  background: url('../../assets/icons/arrows.svg');
  cursor: pointer;
}

.slider-section {
  width: 2px;
  height: 50px;
  background-color: #999;
  z-index: 1;
}
