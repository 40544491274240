.planify-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.upload-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.custom-file-input {
  position: relative;
}

.file-name-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.upload-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #007bff;
  color: #007bff;
}

.upload-button:hover {
  background: #007bff;
  color: #ffffff;
}

.uploaded-alert {
  text-align: center;
  margin-bottom: 20px;
}

.custom-file-input-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-control-file-name {
  cursor: default;
  background-color: #fff;
  border-right: none;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.button-label {
  margin-left: 0.5rem;
}
