.head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.head_h {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 1rem;
    font-size: xx-large;
    text-transform: uppercase;
}

.heading {
    margin: auto;
    text-align: center;
}

.info {
    white-space: nowrap;
    font-size: medium;
    font-weight: 700;
    text-align: end;
    width: fit-content;
}
.edit_info{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
}
.line {
    border: 1px solid black;
    margin-top: 1rem;
   
}


.logo {
  
    max-height: 150px;
   max-width:  300px;
}