.container-dropdown {
  /* optional */
  /* height: 300px; */
}
.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-trigger {
  background: inherit;
  /* border-radius: 90px; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.6rem;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); */
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3); */
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 1.4rem;
  margin: 0 1rem;
}

.menu-trigger img {
  border-radius: 9rem;
}

.c-dropdown-menu {
  background: #ffffff;
  border-radius: 0.8rem;
  position: absolute;
  /* top: -50%; */
  right: 0;
  /* width: 300px; */
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
}

.c-dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}


/* .c-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.c-dropdown-menu li {
  border-bottom: 1px solid #dddddd;
}

.c-dropdown-menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
} */
