.project-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default to 1 column */
  gap: 16px; /* Adjust spacing as needed */
}

@media (min-width: 640px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for small screens */
  }
}

@media (min-width: 1024px) {
  .project-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for large screens */
  }
}

@media (min-width: 1280px) {
  .project-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for extra-large screens */
  }
}
