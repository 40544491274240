.toggle-checked-grey {
  background-color: #ABB2B9 !important;
  border: #ABB2B9 !important;
  color: white !important;
}

.toggle-checked-green {
  background-color: #70bb70 !important;
  border: #70bb70 !important;
  color: white !important;
}

.toggle-green {
  background-color: none !important;
  border: 1px solid #70bb70 !important;
  color: #70bb70 !important;
}

.toggle-checked-blue {
  background-color: #4f8eed !important;
  border: #4f8eed !important;
  color: white !important;
}

.toggle-blue {
  background-color: none !important;
  border: 1px solid #4f8eed !important;
  color: #4f8eed !important;
}
