.srr-list-div {
  height: 75vh;
  padding-right: 1rem;
}

@media only screen and (min-width: 1024px) {
  .project-board-row {
    width: 95%;
    margin: auto;
  }
  .filter-container {
    margin-right: 7.5rem;
  }
  .large-screen-details {
    margin-left: 1.5rem;
  }
  .wrapper__menu__input {
    padding-left: 6.5rem;
  }
  .srr-list-div {
    height: 70vh;
  }
}
