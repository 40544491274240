.tab-component {
  transition-duration: 500ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  cursor: pointer;
  border-bottom: 0.1rem solid #8f9397;
  transition: 0.2s linear;
}
.active-about-tab {
  color: #276fb4;
  font-weight: 800;
  border-bottom: 0.2rem solid #276fb4;
}

.tabcontent {
  animation: fadeEffect 2s ease-in-out; /* Fading effect takes 1 second */
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
