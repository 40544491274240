.card {
    border:none;
    /*padding: 10px 50px;*/
}
@media only screen and (min-width: 961px) {
  .card  {
    padding: 10px 50px;
  }
}
.card::after{
    position: absolute;
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card:hover {


    transform: scale(1.02, 1.02);
    -webkit-transform: scale(1.02, 1.02);
    backface-visibility: hidden;
    will-change: transform;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.75) !important;
}

.card:hover::after {
    opacity: 1;
}

.card:hover .btn-outline-primary{
    color:white;
    background:#007bff;
}
.card1:hover {
    background:#00ffb6;
    border:1px solid #00ffb6;
}

.card1:hover .list-group-item{
    background:#00ffb6 !important
}





.card2:hover {
    background:#00C9FF;
    border:1px solid #00C9FF;
}

.card2:hover .list-group-item{
    background:#00C9FF !important
}


.card3:hover {
    background:#ff95e9;
    border:1px solid #ff95e9;
}

.card3:hover .list-group-item{
    background:#ff95e9 !important
}


.card:hover .btn-outline-dark{
    color:white;
    background:#212529;
}