.crm-table {
  width: auto;
  margin-inline: auto;
  min-width: 75vw;
}

.crm-table>thead>tr>th {
  padding-inline: 15px;
}

.crm-table > thead > tr > th:nth-child(-n+5) {
  text-align: start;
}

.tbody-tr td {
  padding-inline: 15px;
}

.tbody-td:nth-child(-n+5) {
  text-align: start;
}

.crm-table>tbody>tr>td>input {
  min-width: 5vw;
}

.container-crm {
  max-width: 80vw;
  overflow-x: auto;
}

.row-blurred {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}