.PDF {
  width: auto;
  margin: 15px 15px;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PDF_main {
  border: 2px solid;
  font-size: medium;
  width: 100%;
  padding: 1rem;
}

.c_list{
  list-style-type: disc;
  text-align: start;
}

.actions_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-button {
  display: flex;
  gap: 2rem;
  width: fit-content;
  padding: 1rem;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 10px;
}

.edit_input {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.e_input{
  outline: none;
  border: 1px solid #313030;
  padding: 1rem;
  border-radius: 1rem;
}
.edit {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-content: center;
  gap: 5rem;
  margin:1rem;
}

.edit-button:hover {
  transition: transform 0.3s ease;
  transform: scale(0.95);
}

@media screen and (max-width: 768px) {
  .ttp {
    scale: 0.65;
    transform: translate(-25%, -20%);
    width: 145%;
  }
}

@page {
  margin: 0;
  size: 210mm 297mm;
}

@media print {
  html, body {
    width: 210mm; /* A4 width in millimeters */
    height: 297mm; /* A4 height in millimeters */
    margin: 0 auto;
    padding: 0;
  }

  @page {
    margin: 0;
    size: 210mm 297mm;
  }

  @page:footer {
    display: none !important;
  }

  @page:header {
    display: none !important;
  }

  table {
    page-break-inside: avoid;
  }

  #nb{
    display: none !important;
  }

  #pdfContainer {
    height: auto;
  }

  #pdfContainer, #pdfContainer * {
    visibility: visible;
    color: black;
    /* font-size: medium; */
  }

  #B_cont {
    height: 100%;
  }
  #B_cont, #B_cont * {
    visibility: visible;
    font-size: 15px;
    color: black;
  }

  .Toastify {
    display: none;
  }

  .core_h > b, .about_h > b, .performance > h1 > b, .ccp_h > b {
    font-size: large;
  }

  .head_h > b{
    font-size: x-large;
  }

  .performance {
    min-width: none !important;
  }

  #_hj_feedback_container {
    display: none;
  }
}

.print{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:2rem;
  border: 1px solid red;
}

.s-p{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem;
  justify-content: center;
  align-items: center;
}

.s-p > button {
  font-size: medium;
}

body {
  background-color: white !important;
}
